<template>
  <b10-base>
    <b10-toolbar
      :title="title"
      :options="toolbarOptions"
      @click-option="clickToolbarOption"
    />
    <b10-page-content
      :padding="2"
    >
      <v-simple-table>
        <template #default>
          <tbody>
            <tr>
              <td>Versión</td>
              <td
                @click="clickVersion"
              >
                {{ version }}
                <v-chip
                  v-show="clickCount >= 3 && clickCount < 7 && !toolbarOptions.administracionBD.visible"
                  small
                  color="info"
                >
                  Ya casi, faltan {{ 7 - clickCount }}...
                </v-chip>
              </td>
            </tr>
            <tr>
              <td>Versión de base de datos</td>
              <td>{{ databaseVersion }}</td>
            </tr>
            <tr>
              <td>Navegador</td>
              <td>{{ navigator }}</td>
            </tr>
            <tr>
              <td>Versión del navegador</td>
              <td>{{ navigatorVersion }}</td>
            </tr>
            <tr>
              <td>Resolución de pantalla</td>
              <td>{{ screenWidth }}x{{ screenHeight }}</td>
            </tr>
            <tr>
              <td>Sistema operativo</td>
              <td>{{ platform }}</td>
            </tr>
            <tr>
              <td>Standalone</td>
              <td>{{ standalone|humanizeBoolean }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </b10-page-content>
  </b10-base>
</template>

<script>
import { basePageMixin } from '@/mixins/basePageMixin'
import { getDatabaseVersion } from '@/offline/database'

export default {
  mixins: [basePageMixin],
  data () {
    return {
      version: __BETA10_VERSION__,
      standalone: false,
      navigator: '',
      navigatorVersion: '',
      screenWidth: 0,
      screenHeight: 0,
      platform: '',
      databaseVersion: 0,
      clickCount: 0,
      toolbarOptions: {
        administracionBD: {
          title: 'Administración de la base de datos',
          visible: false,
          icon: 'database',
        },
      },
    }
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      this.title = 'Configuración'
      this.standalone = window.matchMedia('(display-mode: standalone)').matches
      this.navigator = navigator.appName
      this.navigatorVersion = navigator.appVersion
      this.screenWidth = window.screen.width
      this.screenHeight = window.screen.height
      this.codename = navigator.appCodeName
      this.platform = navigator.platform
      this.databaseVersion = getDatabaseVersion(this.$offline.db)
    },
    clickToolbarOption (option) {
      if (option === this.toolbarOptions.administracionBD) {
        window.location = 'tools.html'
      }
    },
    clickVersion () {
      if (!this.toolbarOptions.administracionBD.visible) {
        this.clickCount += 1
        if (this.clickCount === 7) {
          this.toolbarOptions.administracionBD.visible = true
          this.clickCount = 0
          this.$alert.showSnackbarSuccess('Las opciones avanzadas ya están disponibles')
        }
      }
    },
  }
}
</script>
